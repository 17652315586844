import { Box, Stack, Typography, Button } from "components";
import { useConsole } from "contexts";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useState } from "react";

function Accordion({ className, children, hidden, title, ...props }) {
  const [expanded, setExpanded] = useState(false);
  const { screen } = useConsole();

  if (hidden) return null;

  return (
    <Stack
      className={classnames("BupAccordion-root", { "Bup-expanded": expanded })}
      spacing={2}
    >
      <Box
        className="BupAccordion-header"
        onClick={() => {
          if (screen.tablet) setExpanded(!expanded);
        }}
      >
        <Typography className="Bup-title" variant="p">
          {title}
        </Typography>
        <Button hidden={!screen.tablet}>{!expanded ? "Ver más" : "Ver menos"}</Button>
      </Box>
      <Stack
        className={classnames("BupAccordion-content", className, {
          "Bup-hidden": screen.tablet && !expanded,
        })}
        spacing={2}
        {...props}
      >
        {children}
      </Stack>
    </Stack>
  );
}

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  component: PropTypes.string,
  hidden: PropTypes.bool,
  spacing: PropTypes.number,
  title: PropTypes.string,
};

export default Accordion;
